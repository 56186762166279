import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from "@angular/router";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TabGuard implements CanActivate {
  /**
   * Constructor
   */
  constructor(private router: Router) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const currentPath = route.routeConfig?.path ?? "";
    return this._check(currentPath);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @param segments
   * @private
   */
  private _check(path: string): Observable<boolean> {
    const tabs: any[] = JSON.parse(localStorage.getItem("tabs") ?? "[]");
    const tabFound = tabs.some((tab) => tab.route == path);
    if (tabFound) {
      return of(true);
    } else {
      this.router.navigate([tabs.length ? tabs[0].route : "login"]);
      return of(false);
    }
  }
}
