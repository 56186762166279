<div class="modal-container">
  <div class="detail-section">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <span class="title">Fixed Deposit Details</span>
      <div class="close-btn" matRipple (click)="closeModal()">
        <img src="../../../../assets/icon/close_icon.svg" alt="close-icon" />
      </div>
    </div>
    <div class="total-investment">
      <span class="total-investment-text">Total Invested Amount</span>
      <span class="amount">₹ {{ data.amount | number: "1.1-2" }}</span>
      <div class="statistics">
        <div class="expected-return-roi">
          <span class="text">Expected Returns</span>
          <span class="value"
            >₹ {{ data.expected_returns | number: "1.1-2" }}
          </span>
        </div>
        <div class="expected-return-roi">
          <span class="text">Expected ROI</span>
          <span class="value">{{ data.expected_roi | number: "1.1-2" }}% </span>
        </div>
      </div>
    </div>
    <div class="detail-row">
      <span class="title">Invest On</span>
      <span class="value">{{ data.created_on | date: "d MMM y" }}</span>
    </div>
    <div class="detail-row">
      <span class="title">Maturity Date</span>
      <span class="value">{{ data.maturity_date | date: "d MMM y" }}</span>
    </div>
    <div class="detail-row">
      <span class="title">Tenure</span>
      <span class="value">{{ data.tenure }}</span>
    </div>
    <div class="detail-row">
      <span class="title">Current FD value</span>
      <span class="value">₹ {{ data.expected_returns | number: "1.1-2" }}</span>
    </div>
    <div class="detail-row">
      <span class="title">time left for maturity</span>
      <span class="value">{{ timeRemaining }}</span>
    </div>
  </div>
  <button type="submit" class="submit-btn" matRipple (click)="closeModal()">
    Make Another Deposit
  </button>
</div>
