<div class="d-flex flex-column">
  <span class="title">{{ data.title }}</span>
  <img [src]="data.image" width="200" height="200" class="align-self-center" />
  <span class="description">{{ data.description }}</span>
  <a
    class="continue-btn"
    matRipple
    [href]="data.link"
    target="_blank"
    mat-dialog-close
    >{{ data.buttonText }}</a
  >
</div>
