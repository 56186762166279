import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ShareFeedbackModalComponent } from "../share-feedback-modal/share-feedback-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { UserAuthService } from "src/services/user-auth.service";
import { GenerateReportModalComponent } from "../generate-report-modal/generate-report-modal.component";
import { JoinUsToInviteModalComponent } from "../join-us-to-invite-modal/join-us-to-invite-modal.component";

@Component({
  selector: "app-side-bar",
  templateUrl: "./side-bar.component.html",
  styleUrls: ["./side-bar.component.scss"],
})
export class SideBarComponent {
  @Output() navbar = new EventEmitter();
  @Input() type: string = "side";
  @Input() mainTabs: any[] = [];

  constructor(
    public dialog: MatDialog,
    public userAuthService: UserAuthService
  ) {}

  toggleNavbar() {
    if (this.type == "over") {
      this.navbar.emit();
    }
  }

  openFeedbackModal() {
    const dialogRef = this.dialog.open(ShareFeedbackModalComponent, {
      data: {},
      width: "504px",
      height: "663px",
      panelClass: ["share-feedback-modal"],
    });
  }

  openReportModal() {
    if (this.userAuthService.accessToken) {
      this.dialog.open(GenerateReportModalComponent, {
        data: {},
        width: "504px",
        height: "674px",
        panelClass: ["generate-report-modal"],
      });
    } else {
      this.dialog.open(JoinUsToInviteModalComponent, {
        width: "522px",
        height: "408px",
        panelClass: ["join-us-to-invite-model"],
      });
    }
  }

  showTab(tabId: string) {
    return this.mainTabs.findIndex((tab) => tab.id == tabId) == -1
      ? false
      : true;
  }
}
