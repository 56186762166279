import { Injectable } from "@angular/core";
import Clarity from "@microsoft/clarity";
import { UserAuthService } from "./user-auth.service";

@Injectable({
  providedIn: "root",
})
export class ClarityService {
  constructor(private userAuthService: UserAuthService) {}

  clarityInit() {
    if (document.location.hostname.includes("app.finzace.com")) {
      Clarity.init("qo8u7x6g3r");
      if (
        this.userAuthService.userId != "" &&
        this.userAuthService.userId != null &&
        this.userAuthService.userId != undefined
      ) {
        Clarity.identify(this.userAuthService.userId);
      }
      console.log(
        "############################\n############################\nClarity Init\n############################\n############################"
      );
    }
  }

  claritySetUserId() {
    if (document.location.hostname.includes("app.finzace.com")) {
      if (
        this.userAuthService.userId != "" &&
        this.userAuthService.userId != null &&
        this.userAuthService.userId != undefined
      ) {
        Clarity.identify(this.userAuthService.userId);
      }
      console.log(
        "############################\n############################\nClarity Set User Id\n############################\n############################"
      );
    }
  }
}
