<div class="side-bar">
  <button
    class="sidebar-nav-btn d-lg-none"
    [routerLink]="['/dashboard']"
    routerLinkActive="selected-tab"
    matRipple
    (click)="toggleNavbar()"
    matTooltip="Dashboard"
    matTooltipPosition="after"
    *ngIf="showTab('DASHBOARD')"
  >
    <span class="selected-indicator"></span>
    <div class="w-100">
      <img
        src="../../../../assets/icon/dashboard-icon-black.svg"
        style="filter: brightness(0.5)"
        alt="dashboard-icon"
        class="unselected"
      />
      <img
        src="../../../../assets/icon/dashboard-icon-black.svg"
        style="filter: brightness(0.5)"
        alt="dashboard-icon"
        class="selected"
      />
    </div>
  </button>
  <button
    class="sidebar-nav-btn d-lg-none"
    [routerLink]="['/fixed-deposit']"
    routerLinkActive="selected-tab"
    matRipple
    (click)="toggleNavbar()"
    matTooltip="Fixed Deposit"
    matTooltipPosition="after"
    *ngIf="showTab('SMART_DEPOSIT')"
  >
    <span class="selected-indicator"></span>
    <div class="w-100">
      <img
        src="../../../../assets/icon/coins-icon-black.svg"
        style="filter: brightness(0.5)"
        alt="smart-deposit-icon"
        class="unselected"
      />
      <img
        src="../../../../assets/icon/coins-icon-black.svg"
        style="filter: brightness(0.5)"
        alt="smart-deposit-icon"
        class="selected"
      />
    </div>
  </button>
  <button
    class="sidebar-nav-btn d-lg-none"
    [routerLink]="['/bonds']"
    routerLinkActive="selected-tab"
    matRipple
    (click)="toggleNavbar()"
    matTooltip="Bonds"
    matTooltipPosition="after"
    *ngIf="showTab('SMART_INVESTMENT')"
  >
    <span class="selected-indicator"></span>
    <div class="w-100">
      <img
        src="../../../../assets/icon/bank-icon-black.svg"
        style="filter: brightness(0.5)"
        alt="smart-invest-icon"
        class="unselected"
      />
      <img
        src="../../../../assets/icon/bank-icon-black.svg"
        style="filter: brightness(0.5)"
        alt="smart-invest-icon"
        class="selected"
      />
    </div>
  </button>
  <button
    class="sidebar-nav-btn d-lg-none"
    [routerLink]="['/smart-saver']"
    routerLinkActive="selected-tab"
    matRipple
    (click)="toggleNavbar()"
    matTooltip="Smart Saver"
    matTooltipPosition="after"
    *ngIf="showTab('SMART_SAVER')"
  >
    <span class="selected-indicator"></span>
    <div class="w-100">
      <img
        src="../../../../assets/icon/piggy-bank-icon-black.svg"
        style="filter: brightness(0.5)"
        alt="smart-saver-icon"
        class="unselected"
      />
      <img
        src="../../../../assets/icon/piggy-bank-icon-black.svg"
        style="filter: brightness(0.5)"
        alt="smart-saver-icon"
        class="selected"
      />
    </div>
  </button>
  <button
    class="sidebar-nav-btn"
    [routerLink]="['/testimonials']"
    routerLinkActive="selected-tab"
    matRipple
    (click)="toggleNavbar()"
    matTooltip="Testimonials"
    matTooltipPosition="after"
  >
    <span class="selected-indicator"></span>
    <div class="w-100">
      <img
        src="../../../../assets/icon/chat.svg"
        alt="chat-icon"
        class="unselected"
      />
      <img
        src="../../../../assets/icon/chat-selected.svg"
        alt="chat-icon"
        class="selected"
      />
    </div>
  </button>
  <button
    class="sidebar-nav-btn"
    matRipple
    matTooltip="Feedback"
    matTooltipPosition="after"
    (click)="openFeedbackModal()"
  >
    <span class="selected-indicator"></span>
    <div class="w-100">
      <img src="../../../../assets/icon/feedback.svg" alt="feedback-icon" />
    </div>
  </button>
  <button
    class="sidebar-nav-btn"
    matRipple
    matTooltip="Reports"
    matTooltipPosition="after"
    (click)="openReportModal()"
  >
    <span class="selected-indicator"></span>
    <div class="w-100">
      <img
        src="../../../../assets/icon/chart-histogram-icon-black.svg"
        alt="report-icon"
        style="filter: contrast(5)"
      />
    </div>
  </button>
  <button
    class="sidebar-nav-btn"
    matRipple
    (click)="toggleNavbar()"
    routerLinkActive="selected-tab"
    [routerLink]="['/terms-of-service']"
    [queryParams]="
      userAuthService.accessToken
        ? { view: 'user-view' }
        : { view: 'auth-view' }
    "
    matTooltip="TnC & Policy"
    matTooltipPosition="after"
  >
    <span class="selected-indicator"></span>
    <div class="w-100">
      <img
        src="../../../../assets/icon/policy.svg"
        alt="policy-icon"
        class="unselected"
      />
      <img
        src="../../../../assets/icon/policy-selected.svg"
        alt="policy-icon"
        class="selected"
      />
    </div>
  </button>
</div>
