import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { ILoginResponse } from "src/models/UserModels";
import { IGenericResponse } from "src/models/genericModels";
import { UserDetailProfile } from "src/models/profile";

@Injectable({
  providedIn: "root",
})
export class UserAuthService {
  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  set accessToken(token: string) {
    localStorage.setItem("authToken", token);
  }

  get accessToken(): string {
    return localStorage.getItem("authToken") ?? "";
  }

  set userId(id: string) {
    localStorage.setItem("userId", id);
  }

  get userId(): string {
    return localStorage.getItem("userId") ?? "";
  }

  set user(id: string) {
    localStorage.setItem("user", id);
  }

  get user(): any {
    return JSON.parse(localStorage.getItem("user") ?? "{}");
  }

  public resetNavbar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  resetNavbar$ = this.resetNavbar.asObservable();

  login(data: any): Observable<ILoginResponse> {
    const url = `${environment.baseUrl}apis/users/v1/login/`;
    const httpOptions = {};
    return this.http.post<ILoginResponse>(url, data, httpOptions);
  }

  loginUsingFirebase(data: any): Observable<ILoginResponse> {
    const url = `${environment.baseUrl}apis/users/v1/login/firebase/`;
    const httpOptions = {};
    return this.http.post<ILoginResponse>(url, data, httpOptions);
  }

  registerUsingFirebase(data: any): Observable<ILoginResponse> {
    const url = `${environment.baseUrl}apis/users/v1/register/firebase/`;
    const httpOptions = {};
    return this.http.post<ILoginResponse>(url, data, httpOptions);
  }

  signup(data: any): Observable<ILoginResponse> {
    const url = `${environment.baseUrl}apis/users/v1/register/`;
    const httpOptions = {};
    return this.http.post<ILoginResponse>(url, data, httpOptions);
  }
  passwordRecovery(data: any): Observable<IGenericResponse> {
    const url = `${environment.baseUrl}apis/users/v1/send-password-recovery-email/`;
    const httpOptions = {};
    return this.http.post<ILoginResponse>(url, data, httpOptions);
  }

  fetchProfileDetails(): Observable<UserDetailProfile> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/users/v3/details/?platform=WEB&version=1.1.1`;
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: token }),
    };
    return this.http.get<UserDetailProfile>(url, httpOptions);
  }

  updateProfileDetails(body: any): Observable<IGenericResponse> {
    const token = `Token ${localStorage.getItem("authToken")}`;
    const url = `${environment.baseUrl}apis/users/userinfo/v1/details/`;
    let httpOptions = {
      headers: new HttpHeaders({ Authorization: token }),
    };
    return this.http.post<IGenericResponse>(url, body, httpOptions);
  }

  fetchUserPolicies(): Observable<any> {
    const url = `${environment.baseUrl}apis/users/userinfo/v1/policies/?user_id=${this.userId}`;
    const httpOptions = {};
    return this.http.get<any>(url, httpOptions);
  }

  acceptUserPolicies(body: any): Observable<any> {
    const url = `${environment.baseUrl}apis/users/userinfo/v1/policies/?user_id=${this.userId}`;
    const httpOptions = {};
    return this.http.post<any>(url, body, httpOptions);
  }

  changePassword(body: any): Observable<IGenericResponse> {
    const url = `${environment.baseUrl}apis/users/v1/change-password/`;
    const httpOptions = {};
    return this.http.post<IGenericResponse>(url, body, httpOptions);
  }

  logout() {
    console.log("In logout");
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(["login"]);
  }

  check(): Observable<boolean> {
    if (this.accessToken) {
      return of(true);
    } else {
      return of(false);
    }
  }
}
