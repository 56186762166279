<div class="modal-container">
  <div>
    <button class="close-btn" matRipple (click)="closeModal()">
      <img
        class="vector"
        src="../../../../assets/image/vector.svg"
        alt="close-icon"
      />
    </button>
  </div>
  <img
    class="warning-image"
    src="{{ assetPath }}"
    alt="investment successfull"
  />
  <span *ngIf="content" class="content">{{ content }}</span>
  <div
    class="title"
    [ngStyle]="{
      color: content ? 'var(--primary-black)' : 'var(--primary-blue)'
    }"
  >
    {{ warningMessage }}
  </div>

  <button
    class="redirect-btn"
    type="button"
    (click)="completeProfile(); closeModal()"
    matRipple
  >
    {{ button_text }}
  </button>
  <button
    *ngIf="data.is_kyc_optional"
    class="redirect-btn"
    type="button"
    matRipple
    (click)="closeModal(true)"
  >
    Continue to Invest
  </button>
</div>
