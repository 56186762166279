<div class="section">
  <div class="d-flex flex-row justify-content-between">
    <div class="tabs-row" *ngIf="mainTabs.length">
      <button
        class="main-tab-btn"
        matRipple
        (click)="changeTab(tab)"
        *ngFor="let tab of mainTabs"
        [routerLink]="['/home', tab.route]"
        routerLinkActive="selected"
      >
        <img
          src="../../../assets/icon/{{
            tab.id == this.selectedTab.id ? tab.icon_light : tab.icon_dark
          }}"
          class="tab-icon"
        />
        <span
          class="tab-btn-text"
          *ngIf="tab.id == selectedTab.id && tab.title.length"
        >
          {{ tab.title }}
        </span>
      </button>
    </div>
    <div *ngIf="show_warning" class="waning-container">
      <img class="warning-icon" src="../../../assets/icon/warning.png" />
      <span>
        <b
          style="cursor: pointer"
          (click)="commonService.navigateToTargetRoute()"
          >{{ warning_action_text }}</b
        >{{ warning_message_text }}</span
      >
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
