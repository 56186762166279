import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Transaction, TransactionStatus } from "../models/transaction";
import { UserDetailProfile } from "src/models/profile";
import { UserAuthService } from "./user-auth.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(
    private snackBar: MatSnackBar,
    private userAuthService: UserAuthService,
    private router: Router
  ) {}

  openSnackbar(message: string) {
    this.snackBar.open(message, "", {
      duration: 3000,
      panelClass: ["mat-toolbar"],
      horizontalPosition: "center",
      verticalPosition: "top",
    });
  }

  blobToJson(blob: Blob): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const text = reader.result as string;
          const json = JSON.parse(text);
          resolve(json);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = reject;
      reader.readAsText(blob);
    });
  }

  downloadBlobAsFile(blob: Blob, fileName: string) {
    const fileURL = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = fileURL;
    a.download = fileName; // You can set the desired file name here
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(fileURL);
  }

  getTransactionStatus(transaction: Transaction): string {
    switch (transaction.status) {
      case TransactionStatus.CREDITED:
        return "ADDED";
      case TransactionStatus.DEBITED:
        return "WITHDRAW";
      default:
        return "";
    }
  }

  getSmartSaverTransactionStatus(transaction: Transaction): string {
    switch (transaction.status) {
      case TransactionStatus.CREDITED:
        return "Completed";
      case TransactionStatus.DEBITED:
        return "Processing";
      default:
        return transaction.status;
    }
  }

  getTransactionIcon(transaction: Transaction): string {
    switch (transaction.status) {
      case TransactionStatus.CREDITED:
        return "added-icon";
      case TransactionStatus.DEBITED:
        return "withdraw-icon";
      default:
        return "";
    }
  }

  navigateToTargetRoute() {
    this.userAuthService.fetchProfileDetails().subscribe({
      next: (response: UserDetailProfile) => {
        console.log("User Profile:", response);
        if (response.data.show_warning) {
          const url = this.getNavigationUrl(response.data.navigate_to);
          if (response.data.navigate_to == "TermsOfService") {
            this.router.navigate([url], {
              queryParams: { view: "user-view", redirect: "true" },
            });
          } else if (
            response.data.navigate_to == "RegistrationFeeEarlyAccessDiscount"
          ) {
            this.router.navigate([url], {
              queryParams: { view: "auth-view", redirect: "true" },
            });
          } else {
            this.router.navigate([url]);
          }
        } else {
          this.router.navigate(["dashboard"]);
        }
      },
      error: (err: any) => {
        console.error("User Profile Error:", err);
      },
    });
  }

  getNavigationUrl(targetRoute: string) {
    if (targetRoute == "EditProfile") {
      return "user-profile";
    } else if (targetRoute == "TermsOfService") {
      return "terms-of-service";
    } else if (targetRoute == "EditBankAccount") {
      return "bank-detail";
    } else if (targetRoute == "PayRegistrationFee") {
      return "registration-fee-page";
    } else if (targetRoute == "RegistrationFeeEarlyAccessDiscount") {
      return "registration-fee-page";
    } else if (targetRoute == "EditAddress") {
      return "address-detail";
    } else if (targetRoute == "EditDocuments") {
      return "profile";
    } else {
      return "dashboard";
    }
  }

  getDay(dateStr: string): string {
    const cleanDateStr = dateStr.replace(/(\d+)(st|nd|rd|th)/, "$1");
    const dateObj = new Date(cleanDateStr);
    if (isNaN(dateObj.getTime())) {
      console.error("Invalid date");
      return "";
    } else {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayOfWeek = daysOfWeek[dateObj.getDay()];
      return dayOfWeek;
    }
  }
}
