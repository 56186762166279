import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HomeConstant } from "src/constants/global-constants";
import { UserAuthService } from "../../../services/user-auth.service";
import { Profile, UserDetailProfile } from "src/models/profile";
import { environment } from "src/environments/environment";
import { CommonService } from "src/services/common.service";
import { featureFlag, featureFlagResponse } from "src/models/featureFlag";
import { FeatureFlagService } from "src/services/feature-flag.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  mainTabs: any[] = [];
  selectedTab: any;
  warning_action_text: string | undefined = "";
  warning_message_text: string | undefined = "";
  show_warning: boolean = false;

  userInfo!: any;
  constructor(
    private router: Router,
    public userAuthService: UserAuthService,
    public commonService: CommonService,
    private featureFlagService: FeatureFlagService
  ) {
    this.userAuthService.resetNavbar$.subscribe((value) => {
      if (value) {
        this.selectedTab = this.changeTab(this.mainTabs[0]);
      }
    });
  }

  fetchTabs() {
    const flagName: string = "TAB_ORDER";
    const flags: featureFlag[] = this.featureFlagService.getFeatureFlags();
    const flagIndex = flags.findIndex((item) => item.name == flagName);
    if (flagIndex < 0) {
      this.featureFlagService.fetchFeatureFlag(flagName).subscribe({
        next: (res: featureFlagResponse) => {
          this.mainTabs = [
            ...this.reOrderTabsList(res.data.additional_details.data),
          ];
          this.initializeCurrentTab();
          console.log(`Feature Flag: ${flagName}:`, res.data);
        },
        error: (err) => {
          console.error(`Feature Flag Error: ${flagName}:`, err);
        },
      });
    } else {
      this.mainTabs = [
        ...this.reOrderTabsList(flags[flagIndex].additional_details.data),
      ];
      this.initializeCurrentTab();
    }
  }

  initializeCurrentTab() {
    this.selectedTab = this.changeTab(this.mainTabs[0]);
    this.mainTabs.forEach((tab) => {
      if (this.router.url.includes(tab.route)) {
        this.changeTab(tab);
      }
    });
  }

  reOrderTabsList(tabListFromFeatureFlag: any[]): any[] {
    const tabs = [...HomeConstant.MainTabs];
    let mainTabs: any[] = [];
    tabListFromFeatureFlag.forEach((tab) => {
      const tabFound = tabs.find((t) => t.tab_name == tab.tab_name);
      if (tabFound) {
        const isVisibleWhenLoggedOut = tab.display_if_logged_out;
        const isVisibleWhenLoggedIn =
          this.userAuthService.accessToken &&
          this.userInfo?.info[
            `show_${tabFound.tab_name.toLowerCase().replace(/ /g, "_")}`
          ];

        if (isVisibleWhenLoggedOut || isVisibleWhenLoggedIn) {
          mainTabs.push(tabFound);
        }
      }
    });
    return mainTabs;
  }

  ngOnInit(): void {
    if (this.userAuthService.accessToken) {
      this.fetchProfileDetails();
    } else {
      if (this.mainTabs.length == 0) {
        this.fetchTabs();
      }
    }
  }

  changeTab(tab: any) {
    this.selectedTab = tab;
  }

  fetchProfileDetails() {
    this.userAuthService.fetchProfileDetails().subscribe({
      next: (response: UserDetailProfile) => {
        console.log("User Profile:", response);
        this.userInfo = response.data;
        this.show_warning = this.userInfo.show_warning;
        this.warning_action_text = this.userInfo.warning_action_text;
        this.warning_message_text = this.userInfo.warning_message_text;
        this.fetchTabs();
      },
      error: (err: any) => {
        console.error("User Profile Error:", err);
      },
    });
  }
}
