export class HomeConstant {
  public static MainTabs: Array<any> = [
    {
      title: "Bonds",
      icon_dark: "bank-icon-black.svg",
      icon_light: "bank-icon-white.svg",
      id: "SMART_INVESTMENT",
      route: "bonds",
      tab_name: "SMART INVEST",
    },
    {
      title: "Fixed Deposit",
      icon_dark: "coins-icon-black.svg",
      icon_light: "coins-icon-white.svg",
      id: "SMART_DEPOSIT",
      route: "fixed-deposit",
      tab_name: "SMART DEPOSIT",
    },
    {
      title: "Dashboard",
      icon_dark: "dashboard-icon-black.svg",
      icon_light: "dashboard-icon-white.svg",
      id: "DASHBOARD",
      route: "dashboard",
      tab_name: "DASHBOARD",
    },
    {
      title: "Smart Saver",
      icon_dark: "piggy-bank-icon-black.svg",
      icon_light: "piggy-bank-icon-white.svg",
      id: "SMART_SAVER",
      route: "smart-saver",
      tab_name: "SMART SAVER",
    },
  ];

  public static SubTabs: Array<any> = [
    // {
    //   title: "Return Calculator",
    //   icon: "calculator-icon-black.svg",
    //   id: "RETURN_CALCULATOR",
    //   route: "return-calculator",
    // },
    { title: "Reports", icon: "chart-histogram-icon-black.svg", id: "REPORTS" },
    { title: "Feedback", icon: "add-icon-black.svg", id: "FEEDBACK" },
  ];
}

export class profilePages {
  public static ACCOUNT_DETAILS: string = "ACCOUNT_DETAILS";
  public static CHANGE_PASSWORD: string = "CHANGE_PASSWORD";
  public static SUPPORT_REQUEST: string = "SUPPORT_REQUEST";
  public static RAISE_REQUEST: string = "RAISE_REQUEST";
}
